.spaceador
  width: 100%
  height: 70px

header
  width: 100%
  border-bottom: 1px solid $border
  position: fixed
  z-index: 4
  .openNotification
    display: none
    background: $white
    border: 1px solid $border
    border-radius: 5px
    left: 50%
    transform: translateX(-50%)
    width: 90%
    z-index: 1
    @media (min-width: 768px)
      width: 50%
      left: 74%
    @media (min-width: 1024px)
      width: 40%
      left: 79%
    @media (min-width: 1280px)
      width: 30%
      left: 84%
    @media (min-width: 1366px)
      width: 25%
      left: 87%
    @media (min-width: 1900px)
      width: 20%
      left: 88%
    .container
      padding: 1rem
      width: 100%
      .tittle
        width: 100%
        justify-content: space-between
        align-items: center
        margin-bottom: 1rem
        p,a
          color: $tittleColor
          font-weight: 600
        a
          font-size: 14px
      .content
        width: 100%
        height: 200px
        overflow-y: scroll
        background: $white
        gap: 1rem
        span
          color: $textColor
          font-size: 13px
        .item
          background: $background
          border: 1px solid $border
          border-radius: 5px
          gap: 10px
          padding: 1rem
          position: relative
          .close
            position: absolute
            right: 15px
            top: 10px
            cursor: pointer
          div
            align-items: flex-start
            p
              font-weight: 600
              margin-bottom: 3px
              span
                font-weight: 400
                font-size: 12px
            h4
                font-weight: 400
                font-size: 13px
                color: $textColor
    hr
      border-top: 1px solid $border
      width: 100%
    .closePanel
      width: 100%
      padding: 15px
      justify-content: center
      a
        text-align: center
        color: $mainColor
        font-weight: 500
header .container
  padding-left: 5%
  justify-content: space-between
  align-items: center
  background: #fff
  @media (min-width: 768px)
    padding-right: 5%
  .openMenu, .notification
    @media (min-width: 1280px)
      cursor: pointer
  .openMenu
    width: 25px
    height: 20px
    @media (min-width: 1280px)
      position: fixed
      z-index: 4
      left: 25px
    span
      position: absolute
      height: 4px
      width: 100%
      background: #777
      border-radius: 4px
      left: 0
      -webkit-transform: rotate(0deg)
      -moz-transform: rotate(0deg)
      -o-transform: rotate(0deg)
      transform: rotate(0deg)
      -webkit-transition: .25s ease-in-out
      -moz-transition: .25s ease-in-out
      -o-transition: .25s ease-in-out
      transition: .25s ease-in-out
      &:nth-child(1) 
        top: 0px
        -webkit-transform-origin: left center
        -moz-transform-origin: left center
        -o-transform-origin: left center
        transform-origin: left center
      &:nth-child(2) 
        top: 50%
        transform: translateY(-50%)
        width: 90%
        -webkit-transform-origin: left center
        -moz-transform-origin: left center
        -o-transform-origin: left center
        transform-origin: left center
      &:nth-child(3) 
        bottom: 0
        -webkit-transform-origin: left center
        -moz-transform-origin: left center
        -o-transform-origin: left center
        transform-origin: left center
  .opened span:nth-child(1) 
    -webkit-transform: rotate(45deg)
    -moz-transform: rotate(45deg)
    -o-transform: rotate(45deg)
    transform: rotate(45deg)
    top: -3px
    width: calc( 100% + 2px )
  .opened span:nth-child(2) 
    width: 0%
    opacity: 0
  .opened span:nth-child(3) 
    -webkit-transform: rotate(-45deg)
    -moz-transform: rotate(-45deg)
    -o-transform: rotate(-45deg)
    transform: rotate(-45deg)
    width: calc( 100% + 2px )
  .notification::before
    content: ''
    position: absolute
    top: -5px
    right: -5px
    border-radius: 50%
    width: 5px
    height: 5px
    background: red
  .logo
    @media (min-width: 768px)
      display: flex
      flex: 1
      justify-content: flex-start
      margin-left: 2rem
      transition: .3s
      a 
        width: 120px
        img
          width: 100%
      .openSlim2, .closeSlim2
        display: none
    @media (min-width: 1280px)
      flex: 0
      gap: 1rem
      align-items: center
      .openSlim2, .closeSlim2
        width: 25px
        img
          width: 100%
      .openSlim2
        display: flex
    /*@media (min-width: 1440px)
      margin-left: 30px
    @media (min-width: 1600px)
      margin-left: 25px
    @media (min-width: 1900px)
      margin-left: 10px
  .logo.logoMovin
    transition: .3s
    @media (min-width: 1280px)
      margin-left: 155px
    @media (min-width: 1900px)
      margin-left: 135px
  .user
    gap: 2rem
    align-items: center
  .userMenu
    background: $background
    align-items: center
    height: 100%
    padding: 1.2rem
    cursor: pointer
    .userImage
      width: 35px
    span, .arrow
      display: none
      @media (min-width: 768px)
        display: inline
    span
      @media (min-width: 768px)
        font-size: 14px
        color: $textColor
        margin: 0 10px
        text-transform: capitalize
    .arrow
      @media (min-width: 768px)
        width: 14px
