//@import url("../css/animate.css")
@import url("../css/jquery.modal.min.css")
@import url("../css/lightpick.css")
  
@import 'components/variables'
@import 'components/base'
@import 'components/mixins'
@import 'components/additionals'
@import 'components/body'
@import 'components/header'
@import 'components/footer'
@import 'login/content'
@import 'tasks/content'
@import 'tasks/edit'
@import 'tasks/modal'

@import 'components/scroll'

*
  font-family: $font-principal
  //user-select: none
  outline: none

input
  user-select: text

.swal-icon img 
  margin: 0 auto
.swal-button
  background-color: $mainColor !important
  color: #fff !important
.swal-button:active
  background-color: $mainColor !important
.swal-button--confirm
  background: $mainColor !important
.swal-footer
  text-align: center !important

.blocker
  z-index: 4
  @media (max-width: 600px)
    padding: 0px


.lightpick__close-action
  display: none
.is-start-date,.is-end-date
  background-color: $mainColor !important
  /*&:hover
    background: $mainColor !important
.lightpick__day.is-in-range
  background-color: rgb(0 0 0 / 28%)
.lightpick__day
  &:hover
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgb(0 0 0 / 10%)' cx='16' cy='16' r='16'/%3E%3C/svg%3E") !important
.lightpick__apply-action
  background-color: $mainColor
  padding: 2px 10px
  font-size: 15px
.lightpick
  @media (max-width: 600px)
    top: 66% !important

.lightpick__day.is-start-date, .lightpick__day.is-end-date, .lightpick__day.is-start-date:hover, .lightpick__day.is-end-date:hover
  background-image: none