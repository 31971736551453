@mixin headTable
  gap: 1rem
  padding: 1rem 10px
  border-bottom: 1px solid $border
  @media (min-width: 768px)
    flex-direction: row
    align-items: center
    justify-content: space-between
  @media (min-width: 1024px)
    padding: 1rem 15px
  .buttons
    justify-content: center
    gap: 1rem
  .buttons a, span
    font-size: 13px
  .buttons a
    border-radius: 5px
    padding: .5rem 1rem 
    background: $background
    font-weight: 500
    &:not(:first-child)
      display: none
    &:first-child
      align-items: center
      background: $mainColor
      color: $white
      img
        margin-right: 5px
    @media (min-width: 1024px)
      &:not(:first-child)
        display: flex
        border: 1px solid $border
        color: $textColor
  .filter
    align-items: center
    justify-content: space-between
    gap: 1rem
    span
      font-size: 14px
      color: $textColor
    input
      padding: 0 10px
      width: 70%
      background: $background
      &::placeholder
        color: $grey
    select
      background: $background
      border: 1px solid $border
      padding: 5px
      color: $textColor

@mixin table
  width: 100%
  border-collapse: collapse
  a
    color: #496ad6
  tr:nth-child(odd)
    background: $background
  tr:nth-child(even)
    background: $white
  tr
    border-bottom: 1px solid $border
    td, th
      padding: 15px 5px
    th
      &:not(:first-of-type)
        .tittle
          justify-content: center
    th .tittle
      justify-content: space-between
      @media (min-width: 768px)
        justify-content: flex-start
        gap: 1rem
      h3
        color: $tittleColor
        font-weight: 600
        font-size: 15px
        width: 100%
      &>img
        cursor: pointer
        @media (max-width: 500px)
          display: none
      .order
        display: flex
        align-items: center
        gap: 5px
        span
          font-size: 12px
        @media (min-width: 500px)
          display: none
    td p
      font-size: 14px
      text-align: center
      font-weight: 500
      word-break: keep-all
    td .nameCar
      align-items: flex-start
      gap: 10px
      .thumbCar
        object-fit: cover
        width: 64px
        height: 100%
      .info
        align-items: flex-start
        flex: 1
        h4
          font-size: 14px
          font-weight: 600
          color: $tittleColor
        &> span
          font-size: 13px
          color: #777
          margin-bottom: 10px
          @media (min-width: 768px)
            margin: 5px 0 0
      .statsMobile
        width: 100%
        justify-content: flex-start
        gap: 1.2rem
        @media (min-width: 500px)
          display: none
        span
          border-radius: 5px
          padding: 4px
          background: $border
          color: $white
          font-size: 8px
          font-weight: 500
          @media (min-width: 390px)
            font-size: 9px
          @media (min-width: 400px)
            font-size: 10px
        span.active
          background: $green
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4)
            background: $mainColor            
        span.inactive
          background: #c4c4c4
        span.sold
          background: $red
    td .stats
      width: 100%
      place-items: center
      span
        padding: 8px 10px
        border-radius: 5px
        font-size: 12px
        font-weight: 500
        color: $white
      span.active
        background: $green
      span.inactive
        background: $grey
      span.sold
        background: $red
    td .featured
      align-items: flex-start
      gap: 10px
      span
        font-size: 11px
        font-weight: 500
        color: $textColor
        background: $border
        padding: 5px
        border-radius: 5px
      .active
        background: $mainColor
        color: white
    td .actions
      align-items: center
      justify-content: center
      gap: 1rem
      a img
        opacity: .6
        transition: opacity .2s
        &:hover
          opacity: 1
      .eye
        position: relative
        span
          background: #f70808
          color: #fff
          padding: 3px 7px
          position: absolute
          top: -10px
          left: -9px
          font-size: 10px
          border-radius: 100px
          z-index: 1
        .green
          background: #12cc50
        img
          width: 18pt
          opacity: 1
    @media (max-width: 500px)
      th, td
        &:not(:first-child)
          display: none

@mixin paginator  
  align-items: center
  padding: 1rem
  gap: 1rem
  @media (min-width: 768px)
    flex-direction: row
    justify-content: space-between
  .total
    color: $grey
    font-size: 14px
    span
      color: $textColor
  .pages
    align-items: center
    gap: 1rem
    img, span
      cursor: pointer
    span
      padding: 10px 1rem
      border-radius: 5px
      color: $textColor
      &:hover
        color: $mainColor
    span.active
      border: 1px solid $mainColor
      color: $mainColor
      cursor: default