$mainColor: #000
$tittleColor: #363636
$textColor: #777777
$background: #F8F8F8
$border: #E2E2E2
$green: #0ACF97
$grey: #c4c4c4
$red: #FF480E
$link: #0AB1DC
$white: #fff

$font-principal: 'Montserrat', sans-serif