footer
  border-top: 1px solid $border
  width: 100%
  .container
    justify-content: center
    align-items: center
    gap: 5px
    padding: 5px 0
    p
      font-size: 12px
      color: $grey

