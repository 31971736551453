*
  margin: 0
  padding: 0
  border: none
  outline: none
  box-sizing: border-box

html
  scroll-behavior: smooth

body
  max-width: 2000px
  margin: auto
  caret-color: $mainColor
  font-family: 'Montserrat', sans-serif
  &::selection, &::-moz-selection
    color: $mainColor
    
.lock-screen
  height: 100%
  overflow: hidden
  width: 100%
  position: fixed

p
  color: $textColor
  font-weight: 400
  font-size: 14px
  text-align: justify

h1, h2, h3
  color: $mainColor
  font-weight: 700

h4
  color: $tittleColor
  
a
  font-size: 14px
  cursor: pointer
  text-decoration: none

ol, ul, menu
  list-style: none

img
  max-width: 100%
  height: auto
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -o-user-select: none
  user-select: none
  -webkit-user-drag: none
  -khtml-user-drag: none
  -moz-user-drag: none
  -o-user-drag: none

.dnone
  display: none

.flex
  display: flex

.colflex
  display: flex
  flex-direction: column

.grid
  display: grid

.w50
  width: 50%
  margin: auto

.w80
  width: 80%
  margin: auto

.w85
  width: 85%
  margin: auto

.w90
  width: 90%
  margin: auto

.relative
  position: relative

.absolute
  position: absolute

input, select
  height: 2.5rem
  border-radius: 5px

input, textarea
  border: 1px solid $border
  &:focus
    border: 1px solid $mainColor

input, select, textarea
  font-size: 16px
  color: $textColor

input[type="checkbox"]
  border-radius: 2px
  border: none
  -webkit-appearance: none
  height: 1.1rem
  width: 1.1rem
  position: relative
  background-color: $border
input[type="checkbox"]:checked
  background-color: $mainColor
input[type="checkbox"]:before, input[type="checkbox"]:checked:before
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  line-height: 1.1em
  text-align: center
  color: white
input[type="checkbox"]:checked:before
  content: '✔'

input[type="radio"] 
  border: 2px solid $border
  -webkit-appearance: none
  height: 1.3rem
  width: 1.3rem
  position: relative
  background: $white
  border-radius: 50%
input[type="radio"]:checked
  border: 2px solid $mainColor
input[type="radio"]:before, input[type="radio"]:checked:before
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 65%
  height: 65%
  border-radius: 50%
input[type="radio"]:checked:before
  content: ''
  background: $mainColor