.addComment
  width: 95%
  max-width: 1000px
  padding: 1rem 0
  border-radius: 5px
  box-shadow: none !important
  background: #202D32
  @media (max-width: 600px)
    width: 100%
  .modal_image
    display: none
    position: fixed
    width: 100%
    height: 100%
    top: 0px
    left: 0px
    padding: 5% 5%
    z-index: 10
    background: #111b21ad
    text-align: right
    div
        color: #fff
        font-weight: 700
        position: absolute
        top: 20px
        right: 5%
        font-size: 20px
        &:hover
            cursor: pointer
    img
        width: 100%
        height: 100%
        object-fit: contain
        background: #111B21
  .container
    width: 100%
  .container 
    justify-content: flex-start  
    align-items: center
    gap: 1rem
    padding: 0 1rem 1rem
    .title
        background: #202D32
        padding: 10px 10px
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        a
            color: #496ad6
        .equis
            display: none
        @media (max-width: 600px)
            display: block
            position: relative
            .equis
                display: block
                position: absolute
                top: 0px
                right: 5px
                font-weight: 700
                color: #fff
                width: 20px
                font-size: 20px
        h4,h5
            color: #fff
            font-size: 15px
            padding: 0px 10px
            font-weight: 400
        h4
            font-weight: 700
        div
            width: 100%
            @media (max-width: 600px)
                text-align: center
                h5
                    text-align: justify
    .chat
        background: #202D32
        height: 270px
        overflow: auto
        border-radius: 20px 0px 0px 20px
        margin-bottom: 1rem
        position: relative
        @media (max-width: 600px)
            height: 350px
        .image
            position: absolute
            width: 100%
            height: 100%
            top: 0px
            left: 0px
            z-index: 2
            background-repeat: no-repeat
            background-size: cover
            background-position: 0% 0%
            filter: grayscale(0.7)
        .messagues
            position: absolute
            width: 100%
            height: 100%
            top: 0px
            left: 0px
            overflow: auto
            padding: 10px 10px
            z-index: 3
        .file_image
            display: none
            position: absolute
            width: 100%
            height: 100%
            top: 0px
            left: 0px
            padding: 10px 10px
            z-index: 4
            background: #111B21
            text-align: center
            img
                width: 100%
                height: 100%
                object-fit: contain
            div
                width: 150px
                height: 150px
                background: #A3AEB4
                margin: 0 auto
                display: none
            h6,h5
                display: none
                margin: 0px
                margin-top: 20px
                font-size: 18px
                font-weight: 700
                color: #A3AEB4
            h5
                font-size: 15px
        .img_active
            img
                display: block
            div,h6,h5
                display: none
        .img_inactive
            img
                display: none
            div,h6,h5
                display: block
        .audio-player
            display: none
            position: absolute
            width: 100%
            height: 100%
            top: 0px
            left: 0px
            padding: 10px 10px
            z-index: 4
            background: #111B21
            text-align: center
            div
                color: #fff
                font-weight: 700
                position: absolute
                top: 10px
                right: 2%
                font-size: 20px
                text-align: right
                &:hover
                    cursor: pointer
            audio
                margin-top: 10%
                width: 100%
        div
            position: relative
            z-index: 2
            width: 100%
            margin-bottom: 1rem
            .h5
                max-width: 100%
                width: fit-content
                padding: 10px 10px
                border-radius: 20px
                color: #015D4B
                font-size: 14px
                margin: 0.2rem 0px
                font-weight: 400
                word-wrap: break-word
                a
                    color: #9caee9
                    word-wrap: break-word
                    &:hover
                        cursor: pointer
                        text-decoration: underline
                .file_a
                    color: #ef7f2d
                    font-weight: 700
                    &:hover
                        cursor: pointer
                        text-decoration: none
                img
                    width: 450px
                    height: 150px
                    object-fit: cover
                audio
                    width: 250px
            h6,span
                color: #fff
                font-size: 13px
                padding: 0px 10px
                font-weight: 400
        .left
            text-align: left
            .h5
                background: #202D32
                color: #fff
        .center
            text-align: center
        .right
            text-align: right
            display: flex
            flex-direction: column
            .h5
                background: #015D4B
                color: #fff
                margin-left: auto
    .text
        text-align: right
        select
            width: 100%
            height: 2.5rem
            background: #2A3843
            border: 1px solid #2A3843
            border-radius: 5px
            color: #D1D6DA
            margin-bottom: 0.5rem
            &::placeholder
                color: #7C8D97
        input,textarea
            width: 100%
            max-width: 100%
            min-width: 100%
            padding: 0 10px
            margin-bottom: 0.5rem
            border-radius: 5px
        textarea
            max-height: 100px
            min-height: 100px
            background: #2A3843
            border: 1px solid #2A3843
            color: #D1D6DA
            &::placeholder
                color: #7C8D97
        .area_div
            position: relative
            textarea
                min-width: 95%
                width: 95%
                margin-left: auto
                left: auto
                right: auto
                float: right
                box-sizing: border-box
                @media (max-width: 600px)
                    min-width: 90%
                    width: 90%
            .file
                width: 0px
                max-width: 0px
                min-width: 0px
                height: 0px
                border: none
                margin: 0px
                padding: 0px
            .adjuntar
                width: 5%
                font-size: 20px
                margin-left: auto
                left: auto
                right: auto
                float: left
                box-sizing: border-box
                min-height: 1px
                text-align: center
                filter: grayscale(1)
                position: absolute
                top: 35px
                &:hover
                    cursor: pointer
            .audio
                width: 5%
                font-size: 22px
                margin-left: 3px
                left: auto
                right: auto
                float: left
                box-sizing: border-box
                min-height: 1px
                text-align: center
                position: absolute
                top: 65px
                .rec
                    visibility: hidden
                    font-size: 10px
                    position: absolute
                    left: 50%
                    z-index: 1
                    animation: moveScale 1s infinite
                @keyframes moveScale
                    0%, 100%
                        opacity: 1
                        transform: scale(1.2)
                    50%
                        opacity: 0.5
                        transform: scale(1)
                .gray
                    filter: grayscale(1)
                &:hover
                    cursor: pointer
            .audio_save
                .rec
                    visibility: inherit
            .dropdown
                width: 5%
                font-size: 20px
                margin-left: auto
                left: auto
                right: auto
                float: left
                box-sizing: border-box
                min-height: 1px
                text-align: center
                position: relative
                .icon
                    filter: grayscale(1)
                &:hover
                    cursor: pointer
                    .dropdown-content
                        display: block !important
                &:hover
            .dropdown-content
                display: none
                position: absolute
                min-width: 500px
                width: 980px
                z-index: 10
                border: 1px solid #D1D6DA
                padding: 10px
                bottom: 110%
                background: #202D32
                border-radius: 5px
                div
                    width: 35px
                    text-align: left
                    background: #202D32
                    float: left
                    margin: 2px 0px
                    font-size: 20px
                    &:hover
                        cursor: pointer
        .status_div
            select
                width: 95%
                margin-left: auto
                left: auto
                right: auto
                float: left
                box-sizing: border-box
                min-height: 1px
                @media (max-width: 600px)
                    width: 100%
                    float: inherit
            div
                width: 4%
                margin-left: auto
                left: auto
                right: auto
                float: right
                box-sizing: border-box
                min-height: 1px
                text-align: center
                @media (max-width: 600px)
                    width: 100%
                img
                    width: 22pt
                    position: relative
                    top: 5px
                    &:hover
                        cursor: pointer
        .button
            justify-content: center
            gap: 1.5rem
            margin-top: 0.5rem
        .a
            padding: 3px 30px
            border-radius: 5px
            font-weight: 500
            align-items: center
            background: transparent
            color: $white
            border: 1px solid none