section.table_section
  padding: 1rem
  border: 1px solid $border
  @media (min-width: 1280px)
    border: none
    padding: 2rem
  .container>.tittle
    justify-content: space-between
    margin-bottom: 1rem
    a
      background: #FFE1CE
      padding: 5px 8px
      border-radius: 5px
      color: $mainColor
      font-size: 13px
      align-items: center
      font-weight: 500
      img
        width: 8px
        margin-right: 5px
    h1
      font-weight: 600
      font-size: 1rem
    @media (min-width: 1280px)
      a
        display: none
      h1
        font-size: 22px
  .internal
    border: 1px solid $border
    background: $white
    .head
      @include headTable
    table
      @include table
      .filters
        td
          padding: 5px
        input,select
          width: 100%
          height: 30px
          border: 1px solid #E2E2E2
          background: #fff
      tr td .userTable
        align-items: center
        gap: 10px
        .thumbRoles
          width: 50px
        .info
          align-items: flex-start
          flex: 1
          h4
            font-size: 14px
            font-weight: 600
            color: $tittleColor
            width: 100%
            text-align: center
          div
            font-size: 13px
            color: #777
            margin: 5px 0
            gap: 5px
            align-items: center
            font-size: 12px
            text-align: center
            width: 100%
            @media (min-width: 768px)
              display: none
          .eye
            position: relative
            display: block
            span
              background: #f70808
              color: #fff
              padding: 3px 7px
              position: relative
              top: -10px
              left: -9px
              font-size: 10px
              border-radius: 100px
              z-index: 1
            .green
              background: #12cc50
            img
              width: 18pt
              opacity: 1
      .priority_id
          background: red
          font-weight: 700
          color: #fff
          border-radius: 10px
          padding: 5px 0px
          margin: 0px 20px
      .priority_id_1
          background: #FF0000
      .priority_id_2
          background: #ff9100
      .priority_id_3
          background: #FFFF00
          color: #000
          font-weight: 300
      .priority_id_4
          background: #00FF00 
      .priority_id_5
          background: #ADFF2F
          color: #000
          font-weight: 300
    .paginator
      @include paginator