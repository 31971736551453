//.orangeup, .orangebottom
  position: fixed
//.orangeup
  top: 0
  left: 0
//.orangebottom
  bottom: 0
  right: 0

section.session
  //position: fixed
  //top: 50%
  //transform: translateY(-50%)
  padding-top: 15%
  justify-content: center
  align-items: stretch
  width: 100%
  @media (min-width: 768px)
    width: 50%
    margin: auto
  @media (min-width: 1024px)
    padding: 0
    width: 100%
    height: 100vh
    overflow-y: hidden
    .imgSession
      display: flex
      width: 60%
      img
        width: 100%
        height: 100%
        object-fit: cover
  @media (min-width: 1366px)
    .imgSession
      width: 55%
  @media (min-width: 1600px)
    .imgSession
      width: 60%
  .login
    width: 80%
    align-items: center
    height: auto
    @media (min-width: 1024px)
      width: 40%
      align-items: center
      justify-content: center
      padding: 0 5%
    @media (min-width: 1366px)
      width: 45%
      padding: 0 10%
    @media (min-width: 1600px)
      width: 40%
    .logo
      width: 80%
    .please
      justify-content: space-between
      margin: 2rem 0
      align-items: center
      width: 100%
      p
        font-size: 12px
      hr
        width: 2rem
        border-top: 1px solid #D8D8D8
    form
      width: 90%
      align-items: center
      .input
        width: 100%
        align-items: center
        img
          position: absolute
          width: 15px
          left: 10px
          top: 50%
          transform: translateY(-50%)
        input
          width: 100%
          padding: 0 10px 0 32px
          color: $textColor
          border-radius: 10px
        &:not(:last-of-type)
          margin-bottom: 1rem
      .forgot
        font-size: 12px
        color: #363636
        margin: 1.5rem 0 2rem
        font-weight: 600
        a
          color: $link
          font-size: 12px
      .init
        margin: 1.5rem 0 2rem
        padding: 1rem 0
        width: 100%
        background: $mainColor
        color: $white
        border-radius: 10px
        text-align: center


