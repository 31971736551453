.scroll
    padding-right: 5px
    &::-webkit-scrollbar-track
        border-radius: 10px
        background-color: #D8D8D8
    &::-webkit-scrollbar
        width: 5px
        background-color: transparent
    &::-webkit-scrollbar-thumb
        border-radius: 10px
        background-color: $textColor
    &::-moz-scrollbar-track
        border-radius: 10px
        background-color: #D8D8D8
    &::-moz-scrollbar
        width: 5px
        background-color: transparent
    &::-moz-scrollbar-thumb
        border-radius: 10px
        background-color: $textColor
    &::-o-scrollbar-track
        border-radius: 10px
        background-color: #D8D8D8
    &::-o-scrollbar
        width: 5px
        background-color: transparent
    &::-o-scrollbar-thumb
        border-radius: 10px
        background-color: $textColor