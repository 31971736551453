.lock-screen
  height: 100%
  overflow: hidden
  width: 100%
  position: fixed

@keyframe width
  from
    width: 0
  to
    width: 40%