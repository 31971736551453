section.editSection
  padding: 1rem
  @media (min-width: 1280px)
    padding: 2rem
    width: 75%
  @media (min-width: 1600px)
    width: 50%
  .tittle
    justify-content: space-between
    margin-bottom: 1rem
    a
      background: #FFE1CE
      padding: 5px 8px
      border-radius: 5px
      color: $mainColor
      font-size: 13px
      align-items: center
      font-weight: 500
      img
        width: 8px
        margin-right: 5px
    h1
      font-weight: 600
      font-size: 1rem
    @media (min-width: 1280px)
      a
        display: none
      h1
        font-size: 22px
  .info
    background: $white
    border: 1px solid $border
    padding: 1rem
    align-items: flex-start
    @media (min-width: 768px)
      padding: 2rem
    h2
      font-size: 18px
      color: $tittleColor
    p
      margin: 1rem 0
    label
      font-size: 15px
      font-weight: 600
      color: $tittleColor
      margin-bottom: 0rem
    select
      width: 100%
      height: 2.5rem
      background: $white
      border: 1px solid $border
      border-radius: 5px
      color: $textColor
      margin-bottom: 1rem
      @media (min-width: 1024px)
        width: 50%
    input,textarea
      width: 100%
      max-width: 100%
      min-width: 100%
      padding: 0 10px
      margin-bottom: 1rem
      border-radius: 5px
      @media (min-width: 1024px)
        width: 50%
        max-width: 50%
        min-width: 50%
    textarea
      max-height: 100px
      min-height: 100px
  .btnActions
    justify-content: center
    gap: 1.5rem
    margin-top: 2rem
    a
      padding: 12px 30px
      border-radius: 5px
      font-weight: 500
      &:first-child
        //display: flex
        align-items: center
        background: $white
        border: 1px solid $border
        color: $textColor
        img
          margin-right: 8px
      &:last-child
        background: $green
        color: $white
    @media (min-width: 1280px)
      justify-content: flex-end
      a
        &:first-child
          display: flex