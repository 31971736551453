body
  display: flex
  flex-direction: column
  background: $background
  main
    display: flex
    @media (min-width: 1280px)
      height: 100%
      .content
        justify-content: space-between
    .menu.whiteBg
      background: #fff
    .menu
      min-height: 100%
      background: $white
      transition: .3s
      @media (min-width: 1280px)
        //width: 220px
        width: 0px
        background: red
        background: none
        .navSlim
          display: none !important
          position: fixed
          place-items: center
          top: 0
          left: 0
          display: grid
          z-index: 2
          height: 100vh
          background: $white
          padding: 74px 1rem 0
          border-right: 1px solid $border
          a:nth-child(1)
            img
              width: 20px
          a:nth-child(2)
            img
              width: 40px
          a:nth-child(6) ,a:nth-child(7), a:nth-child(3)
            img
              width: 20px
          a:last-of-type
            img
              width: 28px
          a
            transition: .3s
            &:not(:last-of-type)
              img
                filter: grayscale(100%)
              &:hover
                img
                  filter: grayscale(0)
          a.selected img
            filter: grayscale(0)
          /*.openSlim
            display: flex
            cursor: pointer 
            width: 25px
            img
              width: 100%
      nav
        position: fixed
        width: calc(100% - 73px)
        height: calc(100vh - 73px )
        background: $white
        z-index: 3
        left: -100%
        top: 74.3px
        //left: 0
        place-items:  start
        padding: 3rem 0 3rem 5rem
        @media (min-width: 768px)
          width: 40%
        @media (min-width: 1024px)
          width: 26%
        @media (min-width: 1280px)
          width: 200px
          top: 0
          //left: 0
          height: 100vh
          padding: 110px 0 3rem 4.5rem
          border-right: 1px solid $border
          /*.closeNav
            display: flex!important
            cursor: pointer
            bottom: 2rem
            right: 1rem
            width: 25px
            img
              width: 100%
        a img
          position: absolute
          left: -2.5rem
          transition: .2s
          filter: grayscale(100%)
          width: 25px
        a
          align-items: center
          font-weight: 600
          font-size: 1rem
          transition: .2s
          @media (min-width: 1280px)
            font-size: 14px
            &:not(.sessionBtn)
              &:hover
                color: $mainColor
                img
                  filter: grayscale(0)
          color: $textColor
        a.selected span
          color: $mainColor
        a.selected img
            filter: grayscale(0)
        //a.selected::before
          //content: ''
          //background: $mainColor
          //position: absolute
          //height: 3px
          //width: 100%
          //bottom: -8px
          //left: 0
          //raya al final, pero falta mediaqueries
          //height: 2.2rem
          //width: 5px
          //left: calc((341px - 5rem) - 5px)
      .sessionBtn
        background: $mainColor
        color: $white
        align-items: center
        gap: 5px
        padding: 8px 10px
        border-radius: 10px
        margin-left: -2.5rem
        font-weight: 400
        font-size: 12px
        img
          position: relative
          left: 0
          width: 15px
    .navOpened
      transition: .3s
      left: 0!important
    .menuOpened
      width: 0px
    .menuClosed
      transition: .3s
      left: -100%!important
    .content
      width: 100%
    #nav-menu
      display: block
      a
        height: 50px
      .sessionBtn
        width: 120%
        height: 35px
    